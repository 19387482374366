import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import themeReducer from './themeReducer';
import activationsReducer from './activationsReducer';

const rootReducer = combineReducers({
  authReducer,
  themeReducer,
  activationsReducer,
});

const store = createStore(
  rootReducer,
);

export default store;
