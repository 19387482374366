import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import {
  InputNumber, ErrorField,
} from '../../../../lib/HooksFormFields';
import styles from './short-term.module.scss';
import { kpis } from '../../../../utils';

const ShortTerm = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { activation } = useOutletContext<any>();
  const arrayKPIs = kpis.shortTerm

  if(!activation || (!activation.market && !activation.maison && !activation.activationType && !activation.year)) return null;

  return (
    <div className={styles.investment}>
      <h1>Short term impact</h1>
      <div className={styles['investment-line']}>
        <div className={styles['investment-col']}>
        </div>
        <div className={styles['investment-col']}>
          <p className={styles['col-label']}>Actual</p>
        </div>
        <div className={styles['investment-col']}>
          <p className={styles['col-label']}></p>
        </div>
      </div>

      {arrayKPIs.map((kpi, index) => (
        <div className={styles['investment-line']} key={`short-term-kpi-line-${index}`}>
          <div className={styles['investment-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['investment-col']}>
            <InputNumber
              name={kpi.value}
              placeholder=''
              control={control}
            />
            {errors?.[kpi.value] && <ErrorField
              message={errors?.[kpi.value]?.message}
            />}
          </div>
          <div className={styles['investment-col']}>
          </div>
        </div>
      ))}

      <div className={styles.separator}></div>
    </div>
  );
};

export default ShortTerm;
