import React from 'react';
import { useSelector } from 'react-redux';
import styles from './header.module.scss';

const Header = () => {
  const user = useSelector((d: any) => d.authReducer.user);
  return user ? (
      <div className={styles.header}>
        <div className={styles.content}>
          <h1>ACTIVATION PLATFORM</h1>
        </div>
      </div>
  ) : null;
};

export default Header;
