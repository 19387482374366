import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormContext, useFieldArray } from 'react-hook-form';
import FieldMoodboard from '../../../../components/FieldMoodboard';
import styles from './moodboard.module.scss';
import {
  InputText,
} from '../../../../lib/HooksFormFields';
import { GrFormClose } from 'react-icons/gr';
import { RiAddLine } from 'react-icons/ri';

const Moodboard = () => {
  const { activation,submit } = useOutletContext<any>();

  const {
    getValues,
    watch,
    control,
  } = useFormContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'videos',
    control,
  });

  if(!activation || (!activation.market && !activation.maison && !activation.activationType && !activation.year)) return null;

  return (
    <div className={styles.moodboard}>
        <h1>Images / Vidéos</h1>
        <div>
          <FieldMoodboard
            name="files"
            control={control}
            submitFile={(obj) => {
              const data = {
                ...(getValues()),
                files: obj.files.filter((d: any) => d?.picture),
              };
              submit(data);
            }}
          />
        </div>
        <div className={styles.videos}>
            {fields?.map((field:any, index:any) => (
                <div className={styles.containerField} key={field.id}>
                <InputText
                    name={`videos.${index}.value`}
                    control={control}
                />
                <div
                    className={styles.remove}
                    onClick={() => remove(index)}
                >
                    <GrFormClose/>
                </div>
                </div>
            ))}
            <button
                className={styles.append}
                onClick={() => append({ value: '' })}
            >
                <span>Add a youtube link</span>
                <div className={styles.icon}>
                  <RiAddLine/>
                </div>
            </button>
        </div>
        <div className={styles.separator}></div>
    </div>
  );
};

export default Moodboard;
