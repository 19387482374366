import React from 'react';
import { useNavigate } from 'react-router-dom';
import IActivation from '../../types/activation';
import styles from './activation.module.scss';

interface IActivationProps {
  activation: IActivation,
  data: any
}

const Activation = ({ activation, data } : IActivationProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.activation} key={activation._id} onClick={() => navigate(`/activations/${activation._id}`)}>
        <div className={styles['activation-col']}><p>{activation.name}</p></div>
        <div className={styles['activation-col']}><p>{activation.market}</p></div>
        <div className={styles['activation-col']}><p>{activation.maison}</p></div>
        <div className={styles['activation-col']}><p>{activation.activationType}</p></div>
        <div className={styles['activation-col']}><p>{activation.year}</p></div>
        <div className={styles['activation-col-separator']}><p></p></div>
        <div className={styles['activation-col-filter']}><p>{(activation?.investment[data.investment]?.actual ? activation?.investment[data.investment]?.actual : "")}</p></div>
        <div className={styles['activation-col-filter']}><p>{(activation?.execution[data.execution]?.actual ? activation?.execution[data.execution]?.actual : "")}</p></div>
        <div className={styles['activation-col-filter']}><p>{(activation?.shortTerm[data.shortTerm]?.actual ? activation?.shortTerm[data.shortTerm]?.actual : "")}</p></div>
        <div className={styles['activation-col-filter']}><p>{(activation?.advanced[data.advanced]?.actual ? activation?.advanced[data.advanced]?.actual : "")}</p></div>
    </div>
  );
};

export default Activation;
