import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Textarea, ErrorField,
} from '../../../../lib/HooksFormFields';
import styles from './description.module.scss';

const Description = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <div className={styles.description}>
      <h1>Description</h1>
      <div className={styles.details}>
        <div className={styles.activationAndBaseline}>
          <div className={styles.containerField}>
            <Textarea
              name="description"
              placeholder='Description...'
              label="Description *"
              control={control}
              maxlength={400}
              rules={{
                required: 'Please enter a form description',
              }}
            />
            {errors?.description && <ErrorField message={errors?.description?.message} />}
          </div>
        </div>
        <div className={styles.picture}>
          <div className={styles.containerField}>
            <Textarea
              name="keyLearnings"
              placeholder='Key Learnings...'
              label="Key Learnings *"
              control={control}
              maxlength={400}
              rules={{
                required: 'Please enter key learnings',
              }}
            />
            {errors?.keyLearnings && <ErrorField message={errors?.keyLearnings?.message} />}
          </div>
        </div>
      </div>
      <div className={styles.separator}></div>
    </div>
  );
};

export default Description;
