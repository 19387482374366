import React, { useState, useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../actions/auth';
import SignInForm from '../../components/form/AuthForm/SignInForm';

import styles from './signin.module.scss';

const SignIn = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    console.log(process.env.NODE_ENV);
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('id');

    if (authReducer.user?._id && mounted) navigate('/activations');
  }, [authReducer.user, mounted]);

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    setMounted(true);
  }, []);

  function submit(data: FieldValues) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.signin}>
      <div className={`${styles.container}${!process.env.REACT_APP_SIGN_IN ? ` ${styles.sso}` : ''}`}>
        {process.env.REACT_APP_SIGN_IN && (
          <div>
            <h1>Connexion</h1>
            <SignInForm
              forgotPwdLink="/forgot-password"
              errorMessage={authReducer.error}
              submit={submit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;
