import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './activation-nav.module.scss';
import IActivation from '../../types/activation';

const ActivationNav = (
  {
    baseUrl,
    activation,
    editable = true,
    isSectionLoaded = false,
  } : {
    baseUrl: string,
    activation?: IActivation,
    editable?: boolean,
    isSectionLoaded?: boolean,
  },
) => {
  const location = useLocation();
  const [section, setSection] = useState<string | null>(null);

  // HANDLE HIGHLIGHT SECTION
  useEffect(() => {
    
    // const handleRouteChange = () => setSection(null);
    const container = document.getElementById('containerScroll');
    if (!container || !isSectionLoaded || !editable) return undefined;
    const sections = container.querySelectorAll('section');

    function handleScroll() {
      let i = 0;
      const offset = 120;
      while (sections[i]?.getBoundingClientRect().bottom < offset) {
        i += 1;
      }
      if (i >= 0 && sections[i].dataset.section) setSection(sections[i].dataset.section || null);
      else { setSection(null); }
    }

    handleScroll();
    container.addEventListener('scroll', handleScroll);

    if(location.pathname !== `${baseUrl}` && location.pathname.split('/')[location.pathname.split('/').length - 1]){
      setSection(location.pathname.split('/')[location.pathname.split('/').length - 1]);
    }

    return () => container.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isSectionLoaded]);

  return (
    <nav className={styles.nav}>
    {editable ? (
      <>
      <p>Menu</p>
      <NavLink
        className={() => (
          (section === 'description') ? styles.active : '')
        }
        to={`${baseUrl}/description`}
      >
        Description
      </NavLink>
      {activation?._id ? (
        <>
          <NavLink
            className={() => (
              (section === 'activation-tags') ? styles.active : '')
            }
            to={`${baseUrl}/activation-tags`}
          >
            Activation tags
          </NavLink>
          {activation?.market || activation?.maison || activation?.activationType || activation?.year ? (
            <>
              <NavLink
                className={() => (
                  (section === 'moodboard') ? styles.active : '')
                }
                to={`${baseUrl}/moodboard`}
              >
                Images / videos
              </NavLink>
              <NavLink
                className={() => (
                  (section === 'investment') ? styles.active : '')
                }
                to={`${baseUrl}/investment`}
              >
                Investment
              </NavLink>
              <NavLink
                className={() => (
                  (section === 'execution') ? styles.active : '')
                }
                to={`${baseUrl}/execution`}
              >
                Execution KPIs
              </NavLink>
              <NavLink
                className={() => (
                  (section === 'short-term') ? styles.active : '')
                }
                to={`${baseUrl}/short-term`}
              >
                Short term impact
              </NavLink>
              <NavLink
                className={() => (
                  (section === 'advanced') ? styles.active : '')
                }
                to={`${baseUrl}/advanced`}
              >
                Advanced KPIs
              </NavLink>
            </>
          ) : (
            <>
              <a className={styles.inactive} >
                Images / videos
              </a>
              <a className={styles.inactive} >
                Investment
              </a>
              <a className={styles.inactive} >
                Execution KPIs
              </a>
              <a className={styles.inactive} >
                Short term impact
              </a>
              <a className={styles.inactive} >
                Advanced KPIs
              </a>
            </>
          )}
          
        </>
      ) : (
        <>
          <a className={styles.inactive} >
            Activation tags
          </a>
          <a className={styles.inactive} >
            Images / videos
          </a>
          <a className={styles.inactive} >
            Investment
          </a>
          <a className={styles.inactive} >
            Execution KPIs
          </a>
          <a className={styles.inactive} >
            Short term impact
          </a>
          <a className={styles.inactive} >
            Advanced KPIs
          </a>
        </>
      )}
      </>
    ) : (
      <>
      </>
    )}
  </nav>
  );
};

export default ActivationNav;
