import { ActionReducer } from '../types';

interface IThemeState {
  tonic: string,
}

const DEFAULT_STATE: IThemeState = {
  tonic: '#EBA996',
};

const activationReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  switch (action.type) {
    case 'THEME_SET_TONIC':
      updatedState = {
        ...state,
        tonic: action.payload,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default activationReducer;
