import { Dispatch } from 'redux';
import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';

import {
  ACTIVATIONS_GET,
  ACTIVATION_GET,
  ACTIVATION_SET,
  ACTIVATION_LIST_GET,
  ACTIVATION_POST,
  ACTIVATION_PUT,
  ACTIVATION_DELETE,
  ACTIVATION_LOADING,
  ACTIVATION_ERROR,
} from './types';
import IActivation from '../types/activation';

const getActivation = async (dispatch: Dispatch, id: string) => {
  const url = `/activations/${id}`;
  dispatch({
    type: ACTIVATION_LOADING,
    payload: ACTIVATION_GET,
  });
  dispatch({
    type: ACTIVATION_GET,
    payload: null,
  });
  const response = await getData(ACTIVATION_ERROR, url, dispatch, true);
  if (response.data?.activation) {
    dispatch({
      type: ACTIVATION_GET,
      payload: response.data?.activation,
    });
  }
};

const setActivation = async (dispatch: Dispatch, activation: IActivation | null) => {
  dispatch({
    type: ACTIVATION_SET,
    payload: activation,
  });
};

const getActivationList = async (dispatch: Dispatch) => {
  const url = '/activations/list';
  dispatch({
    type: ACTIVATION_LOADING,
    payload: ACTIVATION_LIST_GET,
  });
  const response = await getData(ACTIVATION_ERROR, url, dispatch, true);
  if (response.data?.list) {
    dispatch({
      type: ACTIVATION_LIST_GET,
      payload: response.data?.list,
    });
  }
};

const getActivations = async (dispatch: Dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/activations${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: ACTIVATION_LOADING,
    payload: ACTIVATIONS_GET,
  });
  const response = await getData(ACTIVATION_ERROR, url, dispatch, true);
  if (response.data?.activations) {
    dispatch({
      type: ACTIVATIONS_GET,
      payload: response.data,
    });
  }
};

const createActivation = async (dispatch: Dispatch, data: any) => {
  const url = '/activations';
  dispatch({
    type: ACTIVATION_LOADING,
    payload: ACTIVATION_POST,
  });
  const response: any = await postData(ACTIVATION_ERROR, url, dispatch, data, true);
  if (response.data?.activation) {
    dispatch({
      type: ACTIVATION_POST,
      payload: response.data.activation,
    });
  }
  return response;
};

const updateActivation = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedActivation } = data;
  const url = `/activations/${_id}`;
  dispatch({
    type: ACTIVATION_LOADING,
    payload: `${ACTIVATION_PUT}-${_id}`,
  });
  const response = await putData(ACTIVATION_ERROR, url, dispatch, updatedActivation, true);
  if (response.data?.activation) {
    dispatch({
      type: ACTIVATION_PUT,
      payload: response.data,
    });
  }
  return response;
};

const deleteActivation = async (dispatch: Dispatch, data: any) => {
  const url = `/activations/${data._id}`;
  const response: any = await deleteData(ACTIVATION_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: ACTIVATION_DELETE,
      payload: data._id,
    });
  }
  return response;
};

export {
  getActivation,
  setActivation,
  getActivations,
  getActivationList,
  createActivation,
  updateActivation,
  deleteActivation,
};
