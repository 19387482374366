import React, { useEffect, useId } from 'react';
import { useLocation } from 'react-router-dom';
import Description from '../sections/description';
import ActivationTags from '../sections/activation-tags';
import Moodboard from '../sections/moodboard';
import Investment from '../sections/investment';
import Execution from '../sections/execution';
import ShortTerm from '../sections/short-term';
import Advanced from '../sections/advanced';
import styles from './container-scroll.module.scss';

const ContainerScroll = () => {
  const descriptionId = useId();
  const ActivationTagsId = useId();
  const moodboardId = useId();
  const investmentId = useId();
  const executionId = useId();
  const shortTermId = useId();
  const advancedId = useId();
  const { pathname } = useLocation();
  useEffect(() => {
    let target = document.getElementById(descriptionId);
    if (/activation-tags/.test(pathname)) {
      target = document.getElementById(ActivationTagsId);
    }
    if (/moodboard/.test(pathname)) {
      target = document.getElementById(moodboardId);
    }
    if (/investment/.test(pathname)) {
      target = document.getElementById(investmentId);
    }
    if (/execution/.test(pathname)) {
      target = document.getElementById(executionId);
    }
    if (/short-term/.test(pathname)) {
      target = document.getElementById(shortTermId);
    }
    if (/advanced/.test(pathname)) {
      target = document.getElementById(advancedId);
    }
    if (!target) return;

    const scrollOffsetY = (target?.offsetTop || 0) - 60;
    target.parentElement?.parentElement?.scrollTo(0, scrollOffsetY);
  }, [pathname]);

  return (
    <>
      <section data-section='description' id={descriptionId} className={styles.section}>
        <Description />
      </section>
      <section data-section='activation-tags' id={ActivationTagsId} className={styles.section}>
        <ActivationTags />
      </section>
      <section data-section='moodboard' id={moodboardId} className={styles.section}>
        <Moodboard />
      </section>
      <section data-section='investment' id={investmentId} className={styles.section}>
        <Investment />
      </section>
      <section data-section='execution' id={executionId} className={styles.section}>
        <Execution />
      </section>
      <section data-section='short-term' id={shortTermId} className={styles.section}>
        <ShortTerm />
      </section>
      <section data-section='advanced' id={advancedId} className={styles.section}>
        <Advanced />
      </section>
    </>
  );
};

export default ContainerScroll;
