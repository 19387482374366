const get = (obj: Record<string, any> = {}, path: string | string[]): any => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
};

const set = (obj: Record<string, any> = {}, path: string | string[], value: any) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  if (!pathArray) return null;
  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
  return obj;
};

const hexToRGB = (hex: string) => {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);

  // return {r, g, b}
  return { red, green, blue };
};

const getLuminance = (r: number, g: number, b: number) => {
  const [lumR, lumG, lumB] = [r, g, b].map((value) => {
    const sRGB = value / 255;

    return sRGB <= 0.03928 ? sRGB / 12.92 : ((sRGB + 0.055) / 1.055) ** 2.4;
  });

  return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
};

const kpis = {
  investment : [{
    value: "paidMediaInvestment",
    label: "Paid media investment"
  },
  {
    value: "paidToInfluenceurs",
    label: "Payment to influencers"
  },
  {
    value: "activationSetupCost",
    label: "Activation setup cost"
  },
  {
    value: "sponsorship",
    label: "Sponsorship/Other fees"
  },
  {
    value: "freeBottles",
    label: "Free bottles"
  },
  {
    value: "FTT",
    label: "FTT/Trade investment"
  }],
  execution:[{
    value: "attendees",
    label: "# of attendees/visitors/online viewers"
  },
  {
    value: "press",
    label: "# of press representatives contacted"
  },
  {
    value: "trade",
    label: "# of trade representatives contacted"
  },
  {
    value: "reach",
    label: "Active reach of paid/gifted influencers"
  },
  {
    value: "media",
    label: "# of Paid Media Impressions"
  }],
  shortTerm:[{
    value: "total",
    label: "Total # of posts"
  },
  {
    value: "ratio",
    label: "Earned/Owned Post Ratio"
  },
  {
    value: "pr",
    label: "PR value (measurment tbc)"
  },
  {
    value: "organic",
    label: "# of organic press clippings"
  },
  {
    value: "crm",
    label: "CRM - new leads"
  },
  {
    value: "serves",
    label: "# of serves"
  }],
  advanced1:[{
    value: "awareness",
    label: "Awareness uplift vs control"
  },
  {
    value: "consideration",
    label: "Consideration uplift vs control"
  },
  {
    value: "future",
    label: "Future Purchase Intent uplift vs control"
  },
  {
    value: "brand",
    label: "Brand Love uplift vs control"
  },
  {
    value: "worth",
    label: "Worth Paying More For uplift vs control"
  }],
  advanced2:[{
    value: "direct",
    label: "Direct Revenue to Investment Ratio"
  },
  {
    value: "perAttendee",
    label: "Cost per attendee"
  },
  {
    value: "perSample",
    label: "Cost per sample"
  },
  {
    value: "cpm",
    label: "CPM - Paid Social"
  },
  {
    value: "influencer",
    label: "Influencer Cost per Adj Reach"
  }]
}

export {
  get,
  set,
  hexToRGB,
  getLuminance,
  kpis,
};
