import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  useForm,
} from 'react-hook-form';
import {
  StyledSelect,
} from '../../lib/HooksFormFields';
import { getActivations } from '../../actions/activations';
import { getActivationList } from '../../actions/activations';
import IActivation from '../../types/activation';
import styles from './activations.module.scss';
import Activation from '../../components/Activation';
import { kpis } from '../../utils';
import image from '../../assets/images/export.svg';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Activations = () => {
  const dispatch = useDispatch();
  const { authReducer, activationsReducer } = useSelector((d: any) => d);
  const { user } = authReducer;
  const navigate = useNavigate();
  const { list, activations, isLoading } = activationsReducer;
  const query = useQuery();
  const [selectedKpis, setSelectedKpis] = useState({
    investment: kpis?.investment[0]?.value as any,
    execution: kpis?.execution[0]?.value as any,
    shortTerm: kpis?.shortTerm[0]?.value as any,
    advanced: kpis?.advanced1[0]?.value as any,
  });
  const {
    formState: { errors },
    control,
  } = useForm();

  // GET ACTIVATIONS
  useEffect(() => {
    getActivations(dispatch, query);
    getActivationList(dispatch);
  }, []);

  const handleChange = (val:any, type:string) => {
    let keyValues = []
    for(const v of val) {
      keyValues.push(v.value);
    }
    
    if(keyValues.length === 0){
      query.delete(type)
    }else{
      query.set(type, keyValues.join(','));
    }
    navigate({ search: query.toString() });
    getActivations(dispatch, query);
  }

  const exportActivations = async () => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `${localStorage.getItem('token')}`,
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/activations/export`;
  
    try {
      const response = await fetch(url, config);
      if (response.status !== 200) {
        throw new Error();
      }
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlObject;
      link.setAttribute('download', 'activations.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.activations}>
        <div className={styles.content}>
            <div className={styles.banner}>
              <div className={styles.containerField}>
                <StyledSelect
                  name="market"
                  placeholder='MARKET...'
                  isMulti={true}
                  control={control}
                  options={list?.markets?.map((market: string) => (
                    { label: market, value: market }
                  ))}
                  defaultValue={query.get('market')?.split(',').map((market: string) => ({ label: market, value: market }))}
                  handleChange={(val) => {
                    handleChange(val, 'market');
                  }}
                />
              </div>
              <div className={styles.containerField}>
                <StyledSelect
                  name="maison"
                  placeholder='MAISON...'
                  isMulti={true}
                  control={control}
                  options={list?.maisons?.map((maison: string) => (
                    { label: maison, value: maison }
                  ))}
                  defaultValue={query.get('maison')?.split(',').map((market: string) => ({ label: market, value: market }))}
                  handleChange={(val) => {
                    handleChange(val, 'maison');
                  }}
                />
              </div>
              <div className={styles.containerField}>
                <StyledSelect
                  name="type"
                  placeholder='ACTIVATION TYPE...'
                  isMulti={true}
                  control={control}
                  options={list?.types?.map((type: string) => (
                    { label: type, value: type }
                  ))}
                  defaultValue={query.get('type')?.split(',').map((market: string) => ({ label: market, value: market }))}
                  handleChange={(val) => {
                    handleChange(val, 'type');
                  }}
                />
              </div>
              <div className={styles.containerField}>
                <StyledSelect
                  name="year"
                  placeholder='YEAR...'
                  isMulti={true}
                  control={control}
                  options={list?.years?.map((year: string) => (
                    { label: year, value: year }
                  ))}
                  defaultValue={query.get('year')?.split(',').map((market: string) => ({ label: market, value: market }))}
                  handleChange={(val) => {
                    handleChange(val, 'year');
                  }}
                />
              </div>
              {user.role === 'Admin' && (
                <>
                  <NavLink to="/activation-edit/new" className={styles.createNewActivation}>
                    <button>CREATE NEW ACTIVATION</button>
                  </NavLink>
                  <button
                    className={styles.exportActivation}
                    onClick={() => exportActivations()}
                  >
                    <img src={image} alt="export"/>
                  </button>
                </>
              )}
            </div>
            <div className={styles['activations-list']}>
              <div className={styles.activation} key={'activations-header'}>
                  <div className={styles['activation-col']}><p>Name</p></div>
                  <div className={styles['activation-col']}><p>Market</p></div>
                  <div className={styles['activation-col']}><p>Maison</p></div>
                  <div className={styles['activation-col']}><p>Activation Type</p></div>
                  <div className={styles['activation-col']}><p>Year</p></div>
                  <div className={styles['activation-col-separator']}></div>
                  <div className={styles['activation-col-filter']}>
                    <StyledSelect
                      name="investment"
                      className='no-border'
                      placeholder='Investment...'
                      control={control}
                      options={kpis?.investment?.map((investment: any) => (
                        { label: investment.label, value: investment.value }
                      ))}
                      defaultValue={kpis?.investment[0]}
                      handleChange={(val) => {
                        setSelectedKpis({...selectedKpis, investment: val.value });
                      }}
                    />
                  </div>
                  <div className={styles['activation-col-filter']}>
                    <StyledSelect
                      name="execution"
                      className='no-border'
                      placeholder='Execution KPIs...'
                      control={control}
                      options={kpis?.execution?.map((execution: any) => (
                        { label: execution.label, value: execution.value }
                      ))}
                      defaultValue={kpis?.execution[0]}
                      handleChange={(val) => {
                        setSelectedKpis({...selectedKpis, execution: val.value });
                      }}
                    />
                  </div>
                  <div className={styles['activation-col-filter']}>
                    <StyledSelect
                      name="shortTerm"
                      className='no-border'
                      placeholder='Short term impact...'
                      control={control}
                      options={kpis?.shortTerm?.map((shortTerm: any) => (
                        { label: shortTerm.label, value: shortTerm.value }
                      ))}
                      defaultValue={kpis?.shortTerm[0]}
                      handleChange={(val) => {
                        setSelectedKpis({...selectedKpis, shortTerm: val.value });
                      }}
                    />
                  </div>
                  <div className={styles['activation-col-filter']}><StyledSelect
                      name="advanced"
                      className='no-border'
                      placeholder='Advanced KPIs...'
                      control={control}
                      options={(kpis?.advanced1).concat(kpis?.advanced2)?.map((advanced: any) => (
                        { label: advanced.label, value: advanced.value }
                      ))}
                      defaultValue={kpis?.advanced1[0]}
                      handleChange={(val) => {
                        setSelectedKpis({...selectedKpis, advanced: val.value });
                      }}
                    /></div>
              </div>
              {activations.length > 0 && activations.map((activation: IActivation) => (
                <Activation key={activation._id} activation={activation} data={selectedKpis}/>
              ))}
            </div>
        </div>
    </div>
  );
};

export default Activations;
