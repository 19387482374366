import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import {
  InputNumber, ErrorField,
} from '../../../../lib/HooksFormFields';
import styles from './advanced.module.scss';
import { kpis } from '../../../../utils';

const Advanced = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { activation } = useOutletContext<any>();

  const arrayKPIs1 = kpis.advanced1
  const arrayKPIs2 = kpis.advanced2

  if(!activation || (!activation.market && !activation.maison && !activation.activationType && !activation.year)) return null;

  return (
    <div className={styles.investment}>
      <h1>Advanced KPIs</h1>
      <div className={styles['investment-line']}>
        <p className={styles['subtitle']}>Short Term Consumer Impact (measured by Event Measurement research tool for events with investment {'>'} $XX)</p>
      </div>

      {arrayKPIs1.map((kpi, index) => (
        <div className={styles['investment-line']} key={`advanced-kpi-line-1-${index}`}>
          <div className={styles['investment-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['investment-col']}>
            <InputNumber
              name={kpi.value}
              placeholder=''
              control={control}
            />
            {errors?.[kpi.value] && <ErrorField
              message={errors?.[kpi.value]?.message}
            />}
          </div>
          <div className={styles['investment-col']}>
          </div>
        </div>
      ))}

      <div className={styles['investment-line']}>
        <p className={styles['subtitle']}>EFFICIENCY - cost per impact</p>
      </div>
      {arrayKPIs2.map((kpi, index) => (
        <div className={styles['investment-line']} key={`advanced-kpi-line-2-${index}`}>
          <div className={styles['investment-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['investment-col']}>
            <InputNumber
              name={kpi.value}
              placeholder=''
              control={control}
            />
            {errors?.[kpi.value] && <ErrorField
              message={errors?.[kpi.value]?.message}
            />}
          </div>
          <div className={styles['investment-col']}>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Advanced;
