import React, {
  act,
  useEffect, useMemo, useState,
} from 'react';
import {
  useNavigate, Outlet, useParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  useForm, FormProvider, useFormContext, FieldValues,
} from 'react-hook-form';
import { BsArrowLeft } from 'react-icons/bs';
import {
  InputText, ErrorField,
} from '../../lib/HooksFormFields';
import { ACTIVATIONS_GET, ACTIVATION_POST, ACTIVATION_PUT } from '../../actions/types';
import { getActivations, updateActivation, createActivation } from '../../actions/activations';
import styles from './activation-edit.module.scss';
import IActivation from '../../types/activation';
import { IFile } from '../../types/files';
import ActivationNav from '../../components/ActivationNav';
import Loader from '../../components/Loader';

const ContainerTitle = (
  {
    submit,
    isLoading,
    activationID,
  } : {
    submit: (data: FieldValues) => Promise<void>,
    isLoading: string[],
    activationID?: string,
  },
) => {
  const navigate = useNavigate();
  const {
    formState: { isValid, isDirty, errors },
    watch,
    control,
    handleSubmit,
  } = useFormContext();
  const name = watch('name');
  const description = watch('description');
  const keyLearnings = watch('keyLearnings');

  // eslint-disable-next-line max-len
  const asMinimumRequirement = useMemo(() => (!!((name && description && keyLearnings))), [name, description, keyLearnings]);

  return (
    <div className={styles.containerTitle}>
      <button
        onClick={handleSubmit(submit)}
        disabled={!isValid || !asMinimumRequirement || !isDirty}
        className={(!isValid || !asMinimumRequirement || !isDirty) ? 'disabled' : ''}
      >
        Save the activation
      </button>
      <div className={styles.back} onClick={() => {
        if (activationID && activationID !== 'new') navigate(`/activations/${activationID}`);
        else navigate('/activations');
      }}>
        <BsArrowLeft size={32} />
      </div>
      <InputText
        name="name"
        placeholder='Activation name...'
        control={control}
        rules={{
          required: 'Please enter an activation name',
        }}
      />
      {errors?.name && <ErrorField message={errors?.name?.message} />}
      {((activationID && isLoading.includes(`${ACTIVATION_PUT}-${activationID}`))
      || (activationID === 'new' && isLoading.includes(ACTIVATION_POST))) && (
        <div className={styles.containerLoader}>
          <Loader small />
        </div>
      )}
    </div>
  );
};

const ActivationEdit = () => {
  const params = useParams();
  let activationID = params.id;
  const dispatch = useDispatch();
  const {
    changedAt,
    activations,
    activation,
    isLoading,
  } = useSelector((state: any) => state.activationsReducer);

  const [stateActivation, setActivation] = useState<IActivation>();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      examples: [],
    } as FieldValues,
  });

  // GET ACTIVATIONS
  useEffect(() => {
    if (activations.length === 0 && !isLoading.includes(ACTIVATIONS_GET)) {
      getActivations(dispatch);
    }
  }, [activations]);

  // SET ACTIVATION
  useEffect(() => {
    if(activationID === 'new' && activation){
      navigate(`/activation-edit/${activation._id}`);
      const found: IActivation | undefined = activations
        .find((c: IActivation) => c._id === activation._id);

      if (!found) return;
      setActivation(found);
    };
    if (activationID && activationID !== 'new') {
      const found: IActivation | undefined = activations
        .find((c: IActivation) => c._id === activationID);

      if (!found) return;
      setActivation(found);
    }
  }, [changedAt, activations]);

  // INIT FORM
  useEffect(() => {
    if (!stateActivation) return;
    methods.reset({
      name: stateActivation.name,
      description: stateActivation.description,
      keyLearnings: stateActivation.keyLearnings,
      market: stateActivation.market,
      maison: stateActivation.maison,
      activationType: stateActivation.activationType,
      year: stateActivation.year,
      files: stateActivation.files
        ? stateActivation.files.map((e: IFile) => ({
          picture: e?._id,
        }))
        : [],
      videos: stateActivation.videos ? stateActivation.videos.map((e: any) => ({ value: e })) : [],
      totalInvestmentActual: stateActivation.investment.totalInvestment.actual,
      totalInvestmentPlanned: stateActivation.investment.totalInvestment.planned,
      paidMediaInvestment: stateActivation.investment.paidMediaInvestment.actual,
      paidToInfluenceurs: stateActivation.investment.paidToInfluenceurs.actual,
      activationSetupCost: stateActivation.investment.activationSetupCost.actual,
      sponsorship: stateActivation.investment.sponsorship.actual,
      freeBottles: stateActivation.investment.freeBottles.actual,
      FTT: stateActivation.investment.FTT.actual,
      attendeesActual: stateActivation.execution.attendees.actual,
      pressActual: stateActivation.execution.press.actual,
      tradeActual: stateActivation.execution.trade.actual,
      reachActual: stateActivation.execution.reach.actual,
      mediaActual: stateActivation.execution.media.actual,
      attendeesTarget: stateActivation.execution.attendees.target,
      pressTarget: stateActivation.execution.press.target,
      tradeTarget: stateActivation.execution.trade.target,
      reachTarget: stateActivation.execution.reach.target,
      mediaTarget: stateActivation.execution.media.target,
      total: stateActivation.shortTerm.total.actual,
      ratio: stateActivation.shortTerm.ratio.actual,
      pr: stateActivation.shortTerm.pr.actual,
      organic: stateActivation.shortTerm.organic.actual,
      crm: stateActivation.shortTerm.crm.actual,
      serves: stateActivation.shortTerm.serves.actual,
      awareness: stateActivation.advanced.awareness.actual,
      consideration: stateActivation.advanced.consideration.actual,
      future: stateActivation.advanced.future.actual,
      brand: stateActivation.advanced.brand.actual,
      worth: stateActivation.advanced.worth.actual,
      direct: stateActivation.advanced.direct.actual,
      perAttendee: stateActivation.advanced.perAttendee.actual,
      perSample: stateActivation.advanced.perSample.actual,
      cpm: stateActivation.advanced.cpm.actual,
      influencer: stateActivation.advanced.influencer.actual,
    });
  }, [stateActivation?.updatedAt]);

  const submit = async (data: FieldValues) => {
    const videos = data.videos?.map((e: any) => e.value);
    const files = data.files?.map((e: any) => e.picture);
    const investment = {
      totalInvestment: {
        actual: data.totalInvestmentActual ? data.totalInvestmentActual : null,
        planned: data.totalInvestmentPlanned ? data.totalInvestmentPlanned : null,
      },
      paidMediaInvestment: {
        actual: data.paidMediaInvestment ? data.paidMediaInvestment : null,
      },
      paidToInfluenceurs: {
        actual: data.paidToInfluenceurs ? data.paidToInfluenceurs : null,
      },
      activationSetupCost: {
        actual: data.activationSetupCost ? data.activationSetupCost : null,
      },
      sponsorship: {
        actual: data.sponsorship ? data.sponsorship : null,
      },
      freeBottles: {
        actual: data.freeBottles ? data.freeBottles : null,
      },
      FTT: {
        actual: data.FTT ? data.FTT : null,
      },
    };
    const execution = {
      attendees: {
        actual: data.attendeesActual ? data.attendeesActual : null,
        target: data.attendeesTarget ? data.attendeesTarget : null,
      },
      press: {
        actual: data.pressActual ? data.pressActual : null,
        target: data.pressTarget ? data.pressTarget : null,
      },
      trade: {
        actual: data.tradeActual ? data.tradeActual : null,
        target: data.tradeTarget ? data.tradeTarget : null,
      },
      reach: {
        actual: data.reachActual ? data.reachActual : null,
        target: data.reachTarget ? data.reachTarget : null,
      },
      media: {
        actual: data.mediaActual ? data.mediaActual : null,
        target: data.mediaTarget ? data.mediaTarget : null,
      },
    };
    const shortTerm = {
      total: {
        actual: data.total ? data.total : null,
      },
      ratio: {
        actual: data.ratio ? data.ratio : null,
      },
      pr: {
        actual: data.pr ? data.pr : null,
      },
      organic: {
        actual: data.organic ? data.organic : null,
      },
      crm: {
        actual: data.crm ? data.crm : null,
      },
      serves: {
        actual: data.serves ? data.serves : null,
      },
    };
    const advanced = {
      awareness: {
        actual: data.awareness ? data.awareness : null,
      },
      consideration: {
        actual: data.consideration ? data.consideration : null,
      },
      future: {
        actual: data.future ? data.future : null,
      },
      brand: {
        actual: data.brand ? data.brand : null,
      },
      worth: {
        actual: data.worth ? data.worth : null,
      },
      direct: {
        actual: data.direct ? data.direct : null,
      },
      perAttendee: {
        actual: data.perAttendee ? data.perAttendee : null,
      },
      perSample: {
        actual: data.perSample ? data.perSample : null,
      },
      cpm: {
        actual: data.cpm ? data.cpm : null,
      },
      influencer: {
        actual: data.influencer ? data.influencer : null,
      },
    };

    const dataToSend = { ...data };

    delete dataToSend.totalInvestmentActual;
    delete dataToSend.totalInvestmentPlanned;
    delete dataToSend.paidMediaInvestment;
    delete dataToSend.paidToInfluenceurs;
    delete dataToSend.activationSetupCost;
    delete dataToSend.sponsorship;
    delete dataToSend.freeBottles;
    delete dataToSend.FTT;
    delete dataToSend.attendeesActual;
    delete dataToSend.pressActual;
    delete dataToSend.tradeActual;
    delete dataToSend.reachActual;
    delete dataToSend.mediaActual;
    delete dataToSend.attendeesTarget;
    delete dataToSend.pressTarget;
    delete dataToSend.tradeTarget;
    delete dataToSend.reachTarget;
    delete dataToSend.mediaTarget;
    delete dataToSend.total;
    delete dataToSend.ratio;
    delete dataToSend.pr;
    delete dataToSend.organic;
    delete dataToSend.crm;
    delete dataToSend.serves;
    delete dataToSend.awareness;
    delete dataToSend.consideration;
    delete dataToSend.future;
    delete dataToSend.brand;
    delete dataToSend.worth;
    delete dataToSend.direct;
    delete dataToSend.perAttendee;
    delete dataToSend.perSample;
    delete dataToSend.cpm;
    delete dataToSend.influencer;
    
    dataToSend.investment = investment;
    dataToSend.execution = execution;
    dataToSend.shortTerm = shortTerm;
    dataToSend.advanced = advanced;
 
    if (activationID && activationID !== 'new') {
      updateActivation(dispatch, {
        _id: activationID,
        ...dataToSend,
        videos,
        files,
      });
    } else {
      createActivation(dispatch, {
        ...dataToSend,
        videos,
        files,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.edit}>
        <div className={styles.sidebar}>
          <ContainerTitle
            submit={submit}
            activationID={activationID}
            isLoading={isLoading}
          />
          <ActivationNav
            baseUrl={`/activation-edit/${activationID}`}
            activation={stateActivation}
            isSectionLoaded={!!stateActivation}
          />
        </div>
        <div id='containerScroll' className={styles.container}>
          <div className={styles.form}>
            {activations.length > 0 && (
              <Outlet context={{ activation: stateActivation, activationID: activationID, submit }} />
            )}
            {isLoading.includes(ACTIVATIONS_GET) && (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default ActivationEdit;
