import React, { useEffect } from 'react';
import {
  Routes, Route, useNavigate, useLocation, useSearchParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileAction } from './actions/auth';
import Layout from './components/Layout';
import Loader from './components/Loader';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import SignIn from './pages/signin';
import Activations from './pages/activations';

import ActivationEdit from './pages/activation-edit';
import ActivationContainerScroll from './pages/activation-edit/ContainerScroll';

import Activation from './pages/activation';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let token = localStorage.getItem('token');
    const params = new URLSearchParams(searchParams);
    const tokenParams = params.get('token');

    //* SET NEW TOKEN IF PARAM TOKEN
    if (!token && tokenParams) {
      token = `JWT ${tokenParams}`;
      localStorage.setItem('token', `${token}`);
      params.delete('token');
      setSearchParams(params);
    }

    if (token) {
      getProfileAction(dispatch).then(() => {
        if ([
          '',
          '/',
          '/sso',
          '/login',
          '/forgot-password',
          '/reset-password',
        ].includes(location.pathname)) {
          navigate('/activations');
        }
      }).catch(() => {
        params.delete('token');
        navigate('/login');
      });
    } else navigate('/login');
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Loader />
    </div>
  );
};

const AppRoutes = () => {
  const { user } = useSelector((d: any) => d.authReducer);

  return (
    <Routes>
      <Route
        path="*"
        element={(<Layout key="app">
          <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            {user && (
              <Route
                path="*"
                element={(
                  <Routes>
                    <Route path="activations" element={<Activations />} />
                    {user.role === 'Admin' && (
                      <>
                        <Route path="activation-edit/:id/:page?" element={<ActivationEdit />}>
                          <Route index element={<ActivationContainerScroll />} />
                        </Route>
                      </>
                    )}
                    <Route path="activations/:id" element={<Activation />} />
                    <Route path="*" element={<Activations />} />
                  </Routes>
                )}
              />
            )}
            <Route path="/sso" element={<Redirect />} />
            <Route path="*" element={<Redirect />} />
          </Routes>
        </Layout>)}
      />
    </Routes>
  );
};

export default AppRoutes;
