import React, {
  useCallback,
} from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import styles from './activation-tags.module.scss';

const ActivationTags = () => {
  const { activation, submit } = useOutletContext<any>();
  const { getValues } = useFormContext();

  const handleAnswer = useCallback(async (tag: string, value: string) => {
    const data = {
      ...(getValues()),
    };
    data[tag] = value;
    submit(data);
  }, [activation]);

  const tags = [
    {
      label: 'Market',
      slug: 'market',
      data: ['US', 'Europe'],
    },
    {
      label: 'Maison',
      slug: 'maison',
      data: ['Moet', 'Maison 2'],
    },
    {
      label: 'Activation Type',
      slug: 'activationType',
      data: ['Type 1', 'Type 2', 'Type 3'],
    },
    {
      label: 'Year / Quarter',
      slug: 'year',
      data: ['2022', '2023', '2024'],
    },
  ];

  if(!activation) return null;

  return (
    <div className={styles['activation-tags']}>
      <h1>Activation Tags</h1>
      <div className={styles.notations}>
        <div className={styles.notation}>
          {tags.map((tag, tindex) => (
            <div className={styles.notation} key={`tag-${tindex}`}>
              <label>{tag.label}</label>
              {tag.data.map((value, vindex) => (
                <div
                  key={`tag-value-${vindex}`}
                  className={`${styles.note} ${getValues() && getValues()[tag.slug] === value ? styles.selected : ''}`}
                  onClick={() => handleAnswer(tag.slug, value)}
                >
                  <p>{value}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.separator}></div>
    </div>
  );
};

export default ActivationTags;
