import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import {
  InputNumber, ErrorField,
} from '../../../../lib/HooksFormFields';
import styles from './execution.module.scss';
import { kpis } from '../../../../utils';

const Execution = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { activation } = useOutletContext<any>();
  const arrayKPIs = kpis.execution

  if(!activation || (!activation.market && !activation.maison && !activation.activationType && !activation.year)) return null;

  return (
    <div className={styles.investment}>
      <h1>Execution KPIs - Delivery/Reach</h1>
      <div className={styles['investment-line']}>
        <div className={styles['investment-col']}>
        </div>
        <div className={styles['investment-col']}>
          <p className={styles['col-label']}>Actual</p>
        </div>
        <div className={styles['investment-col']}>
          <p className={styles['col-label']}>Target</p>
        </div>
      </div>

      {arrayKPIs.map((kpi, index) => (
        <div className={styles['investment-line']} key={`exection-kpi-line-${index}`}>
          <div className={styles['investment-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['investment-col']}>
            <InputNumber
              name={`${kpi.value}Actual`}
              placeholder=''
              control={control}
            />
            {errors?.[`${kpi.value}Actual`] && <ErrorField
              message={errors?.[`${kpi.value}Actual`]?.message}
            />}
          </div>
          <div className={styles['investment-col']}>
          <InputNumber
              name={`${kpi.value}Target`}
              placeholder=''
              control={control}
            />
            {errors?.[`${kpi.value}Target`] && <ErrorField
              message={errors?.[`${kpi.value}Target`]?.message}
            />}
          </div>
        </div>
      ))}

      <div className={styles.separator}></div>
    </div>
  );
};

export default Execution;
