import React from 'react';
import IActivation from '../../../../types/activation';
import styles from './kpis.module.scss';

interface IActivationProps {
  activation: IActivation,
}

const Kpis = ({ activation } : IActivationProps) => {

  let arrayInvestment = [{
    value: activation.investment.paidMediaInvestment.actual,
    label: "Paid media investment"
  },
  {
    value: activation.investment.paidToInfluenceurs.actual,
    label: "Payment to influencers"
  },
  {
    value: activation.investment.activationSetupCost.actual,
    label: "Activation setup cost"
  },
  {
    value: activation.investment.sponsorship.actual,
    label: "Sponsorship/Other fees"
  },
  {
    value: activation.investment.freeBottles.actual,
    label: "Free bottles"
  },
  {
    value: activation.investment.FTT.actual,
    label: "FTT/Trade investment"
  }]

  let arrayExecution = [{
    actual: activation.execution.attendees.actual,
    target: activation.execution.attendees.target,
    label: "# of attendees/visitors/online viewers"
  },
  {
    actual: activation.execution.press.actual,
    target : activation.execution.press.target,
    label: "# of press representatives contacted"
  },
  {
    actual: activation.execution.trade.actual,
    target : activation.execution.trade.target,
    label: "# of trade representatives contacted"
  },
  {
    actual: activation.execution.reach.actual,
    target : activation.execution.reach.target,
    label: "Active reach of paid/gifted influencers"
  },
  {
    actual: activation.execution.media.actual,
    target : activation.execution.media.target,
    label: "# of Paid Media Impressions"
  }]

  let arrayShortTerm = [{
    value: activation.shortTerm.total.actual,
    label: "Total # of posts"
  },
  {
    value: activation.shortTerm.ratio.actual,
    label: "Earned/Owned Post Ratio"
  },
  {
    value: activation.shortTerm.pr.actual,
    label: "PR value (measurment tbc)"
  },
  {
    value: activation.shortTerm.organic.actual,
    label: "# of organic press clippings"
  },
  {
    value: activation.shortTerm.crm.actual,
    label: "CRM - new leads"
  },
  {
    value: activation.shortTerm.serves.actual,
    label: "# of serves"
  }]

  let arrayAdvanced1 = [{
    value: activation.advanced.awareness.actual,
    label: "Awareness uplift vs control"
  },
  {
    value: activation.advanced.consideration.actual,
    label: "Consideration uplift vs control"
  },
  {
    value: activation.advanced.future.actual,
    label: "Future Purchase Intent uplift vs control"
  },
  {
    value: activation.advanced.brand.actual,
    label: "Brand Love uplift vs control"
  },
  {
    value: activation.advanced.worth.actual,
    label: "Worth Paying More For uplift vs control"
  }]

  let arrayAdvanced2 = [{
    value: activation.advanced.direct.actual,
    label: "Direct Revenue to Investment Ratio"
  },
  {
    value: activation.advanced.perAttendee.actual,
    label: "Cost per attendee"
  },
  {
    value: activation.advanced.perSample.actual,
    label: "Cost per sample"
  },
  {
    value: activation.advanced.cpm.actual,
    label: "CPM - Paid Social"
  },
  {
    value: activation.advanced.influencer.actual,
    label: "Influencer Cost per Adj Reach"
  }]

  return (
    <div className={styles.kpis}>
      <div className={styles['kpi-line-header']}>
        <div className={styles['kpi-col']}>
          <p>Investment</p>
        </div>
        <div className={styles['kpi-col']}>
          <p>Planned</p>
        </div>
        <div className={styles['kpi-col']}>
          <p>Actual</p>
        </div>
      </div>
      <div className={styles['kpi-line']}>
        <div className={styles['kpi-col']}>
          <p>TOTAL INVESTMENT</p>
        </div>
        <div className={styles['kpi-col']}>
          <p>{activation?.investment?.totalInvestment?.planned}</p>
        </div>
        <div className={styles['kpi-col']}>
          <p>{activation?.investment?.totalInvestment?.actual}</p>
        </div>
      </div>
      {arrayInvestment.map((kpi, index) => (
        <div className={styles['kpi-line']} key={`kpi-line-1-${index}`}>
          <div className={styles['kpi-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p></p>
          </div>
          <div className={styles['kpi-col']}>
            <p>{kpi.value}</p>
          </div>
        </div>
      ))}
      <div className={styles['kpi-line-header']}>
        <div className={styles['kpi-col']}>
          <p>Execution KPIs - Delivery/reach</p>
        </div>
        <div className={styles['kpi-col']}>
          <p>Target</p>
        </div>
        <div className={styles['kpi-col']}>
          <p>Actual</p>
        </div>
      </div>
      {arrayExecution.map((kpi, index) => (
        <div className={styles['kpi-line']} key={`kpi-line-2-${index}`}>
          <div className={styles['kpi-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p>{kpi.target}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p>{kpi.actual}</p>
          </div>
        </div>
      ))}
      <div className={styles['kpi-line-header']}>
        <div className={styles['kpi-col']}>
          <p>Short term impact</p>
        </div>
        <div className={styles['kpi-col']}>
          <p>Actual</p>
        </div>
        <div className={styles['kpi-col']}>
          <p></p>
        </div>
      </div>
      {arrayShortTerm.map((kpi, index) => (
        <div className={styles['kpi-line']} key={`kpi-line-3-${index}`}>
          <div className={styles['kpi-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p>{kpi.value}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p></p>
          </div>
        </div>
      ))}
      <div className={styles['kpi-line-header']}>
        <div className={styles['kpi-col']}>
          <p>Advanced KPIs</p>
        </div>
        <div className={styles['kpi-col']}>
          <p></p>
        </div>
        <div className={styles['kpi-col']}>
          <p></p>
        </div>
      </div>
      <div className={styles['kpi-line']}>
        <p className={styles['subtitle']}>Short Term Consumer Impact (measured by Event Measurement research tool for events with investment {'>'} $XX)</p>
      </div>
      {arrayAdvanced1.map((kpi, index) => (
        <div className={styles['kpi-line']} key={`kpi-line-4-${index}`}>
          <div className={styles['kpi-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p>{kpi.value}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p></p>
          </div>
        </div>
      ))}
      <div className={styles['kpi-line']}>
        <p className={styles['subtitle']}>EFFICIENCY - cost per impact</p>
      </div>
      {arrayAdvanced2.map((kpi, index) => (
        <div className={styles['kpi-line']} key={`kpi-line-5-${index}`}>
          <div className={styles['kpi-col']}>
            <p>{kpi.label}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p>{kpi.value}</p>
          </div>
          <div className={styles['kpi-col']}>
            <p></p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Kpis;
