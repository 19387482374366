// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';

// ACTIONS ACTIVATIONS
export const ACTIVATIONS_GET = 'ACTIVATIONS_GET';
export const ACTIVATION_GET = 'ACTIVATION_GET';
export const ACTIVATION_SET = 'ACTIVATION_SET';
export const ACTIVATION_LIST_GET = 'ACTIVATION_LIST_GET';
export const ACTIVATION_POST = 'ACTIVATION_POST';
export const ACTIVATION_PUT = 'ACTIVATION_PUT';
export const ACTIVATION_DELETE = 'ACTIVATION_DELETE';
export const ACTIVATION_LOADING = 'ACTIVATION_LOADING';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';

// ACTIONS FILES
export const FILE_ERROR = 'FILE_ERROR';
