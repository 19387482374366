import {
  ACTIVATIONS_GET,
  ACTIVATION_GET,
  ACTIVATION_SET,
  ACTIVATION_LIST_GET,
  ACTIVATION_POST,
  ACTIVATION_PUT,
  ACTIVATION_DELETE,
  ACTIVATION_LOADING,
  ACTIVATION_ERROR,
} from '../actions/types';
import { ActionReducer } from '../types';
import IActivation from '../types/activation';

interface IActivationState {
  activation: IActivation | null,
  activations: IActivation[],
  list: any | null,
  isLoading: string[],
  error: string | null,
  changedAt: number,
}

const DEFAULT_STATE: IActivationState = {
  activation: null,
  activations: [],
  list: {},
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const activationReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const updateActivations: any = state.activations || [];
  const activationIndex = (updateActivations || []).findIndex(
    (p: any) => p._id === action.payload?.activation?._id,
  );
  switch (action.type) {
    case ACTIVATION_GET:
    case ACTIVATION_SET:
      updatedState = {
        ...state,
        activation: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== ACTIVATION_GET),
        error: null,
      };
      break;
    case ACTIVATIONS_GET:
      updatedState = {
        ...state,
        activation: null,
        activations: action.payload.activations,
        isLoading: state.isLoading?.filter((d) => d !== ACTIVATIONS_GET),
        error: null,
      };
      break;
    case ACTIVATION_LIST_GET:
      updatedState = {
        ...state,
        list: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== ACTIVATION_LIST_GET),
        error: null,
      };
      break;
    case ACTIVATION_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        activation: action.payload,
        activations: [action.payload, ...state.activations].sort(
          (a, b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime(),
        ),
        isLoading: state.isLoading?.filter((d) => d !== ACTIVATION_POST),
        error: null,
      };
      break;
    case ACTIVATION_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        activations: state.activations.filter((activation: any) => activation._id !== action.payload),
        isLoading: state.isLoading?.filter((d) => d !== ACTIVATION_DELETE),
        error: null,
      };
      break;
    case ACTIVATION_PUT:
      if (typeof activationIndex === 'number') {
        updateActivations[activationIndex] = action.payload.activation;
      }

      updatedState = {
        ...state,
        activation: action.payload.activation,
        activations: updateActivations.sort(
          (a: any, b: any) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime(),
        ),
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter(
          (d) => d !== `ACTIVATION_PUT-${action.payload.activation._id}`,
        ),
        error: null,
      };
      break;
    case ACTIVATION_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case ACTIVATION_ERROR:
      // eslint-disable-next-line no-case-declarations
      let message = action?.payload?.data?.error;
      if (message === 'That email address is already in use.') {
        message = 'Cette adresse email est déjà associée à un compte';
      } else if (message === 'Unauthorized to create an activation with this role') {
        message = 'Vous n\'êtes pas autoriser à assigner ce rôle';
      }
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default activationReducer;
