/* eslint-disable no-dupe-keys */
const styleBase = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    // overflow: 'hidden',
  }),
  control: (provided: any) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: 'rgba(255,255,255,0.5)',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    minHeight: '40px',
    minWidth: '150px',
    borderRadius: '3px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'CenturyGothic',
    fontSize: '12px',
    color: '#FFF',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '12px', color: '#FFF' }),
  multiValue: (provided) => ({ ...provided, fontSize: '12px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    padding: '8px 4px',
    '&:hover': {
      color: 'rgba(255,255,255,0.5)',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: state.isSelected ? '#edebeb' : '',
    color: '#000000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#edebeb' : '#dbd7d7',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '37px',
  }),
};

const styleBold = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    // overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: '#000000',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    minHeight: '40px',
    borderRadius: '3px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'CenturyGothic-Bold',
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#636363',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: 'CenturyGothic-Bold',
    textTransform: 'uppercase',
  }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: 'CenturyGothic-Bold',
    textTransform: 'uppercase',
  }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    fontFamily: 'CenturyGothic-Bold',
    textTransform: 'uppercase',
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#000000',
    padding: '8px 4px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: state.isSelected ? '#edebeb' : '',
    color: '#000000',
    cursor: 'pointer',
    fontFamily: 'CenturyGothic-Bold',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: state.isSelected ? '#edebeb' : '#dbd7d7',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '37px',
  }),
};

export {
  styleBase,
  styleBold,
};
