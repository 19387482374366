import React, { useEffect, useState } from 'react';
import {
  NavLink,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { ACTIVATIONS_GET } from '../../actions/types';
import { getActivations } from '../../actions/activations';
import styles from './activation.module.scss';
import IActivation from '../../types/activation';
import Loader from '../../components/Loader';
import Moodboard from './sections/moodboard';
import Kpis from './sections/kpis';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';
import imageExport from '../../assets/images/export.svg';
import imageDuplicate from '../../assets/images/duplicate.svg';
import { createActivation } from '../../actions/activations';

const downloadPPTX = async (id: any) => {
  const config = {
    method: 'GET',
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
    },
  };
  const url = `${process.env.REACT_APP_API_URL}/activations/${id}/export`;

  try {
    const response = await fetch(url, config);
    if (response.status !== 200) {
      throw new Error();
    }
    const blob = await response.blob();
    const urlObject = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', 'export.pptx');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    console.log(e);
  }
};

const Activation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const activationID = params.id;
  const dispatch = useDispatch();
  const { authReducer, activationsReducer } = useSelector((state: any) => state);
  const { user } = authReducer;
  const { changedAt, activations, isLoading } = activationsReducer;
  const activationR = activationsReducer.activation
  const [activation, setActivation] = useState<IActivation>();
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
  const [keylearningsOpen, setKeylearningsOpen] = useState<boolean>(false);

  // GET ACTIVATIONS
  useEffect(() => {
    if (activations.length === 0 && !isLoading.includes(ACTIVATIONS_GET)) {
      getActivations(dispatch);
    }
  }, [activations]);

  useEffect(() => {
    if (activationID) {
      const found: IActivation | undefined = activations
        .find((c: IActivation) => c._id === activationID);

      if (!found) return;
      setActivation(found);
    }
  }, [changedAt, activations]);

  const duplicateActivation = async () => {
    let obj = {...activation}
    delete obj.files
    delete obj._id
    delete obj.videos
    delete obj.createdAt
    delete obj.updatedAt
    createActivation(dispatch, obj)
  };

  useEffect(() => {
    if(activationR && activationR._id){
      if(activationR._id !== activationID) navigate(`/activation-edit/${activationR._id}`)
    }
  }, [activationR]);

  return (
    <div className={styles.activation}>
      <div className={styles.sidebar}>
        <div className={styles.containerTitle}>
          <div className={styles.back} onClick={() => navigate('/activations')}>
            <BsArrowLeft size={32} />
          </div>
          <h1>{activation?.name}</h1>
        </div>
        <div className={styles.containerTags}>
          <div className={styles.containerTag}>
            {activation?.market}
          </div>
          <div className={styles.containerTag}>
            {activation?.maison}
          </div>
          <div className={styles.containerTag}>
            {activation?.activationType}
          </div>
          <div className={styles.containerTag}>
            {activation?.year}
          </div> 
        </div>
        <div className={styles.container}>
          <div className={styles.toggle}>
            <h4>Description</h4>
            <a className={styles.icon} onClick={() => setDescriptionOpen(!descriptionOpen)}>
              {descriptionOpen ? <RiSubtractLine /> : <RiAddLine />}
            </a>
          </div>
          {descriptionOpen ? <p>{activation?.description}</p> : <></>}
        </div>
        <div className={styles.container}>
          <div className={styles.toggle}>
            <h4>Key Learnings</h4>
            <a className={styles.icon} onClick={() => setKeylearningsOpen(!keylearningsOpen)}>
            {keylearningsOpen ? <RiSubtractLine /> : <RiAddLine />}
            </a>
          </div>
          {keylearningsOpen ? <p>{activation?.keyLearnings}</p> : <></>}
        </div>
        <div className={styles.container}>
          {activation && <Moodboard activation={activation} />}
        </div>
      </div>
      <div className={styles.kpis}>
        {activation && <Kpis activation={activation} />}
      </div>
      <div className={styles.toolbar}>
        {user.role === 'Admin' && (
          <>
          <NavLink to={`/activation-edit/${activationID}`}>
            <button>Edit the activation</button>
          </NavLink>
          <button
            className={styles.duplicateActivation}
            onClick={() => duplicateActivation()}
          >
            <img src={imageDuplicate} alt="duplicate"/>
          </button>
          <button
            className={styles.exportActivation}
            onClick={() => downloadPPTX(activationID)}
          >
            <img src={imageExport} alt="export"/>
          </button>
        </>
        )}
      </div>
      {isLoading.includes(ACTIVATIONS_GET) && (
        <Loader />
      )}
    </div>
  );
};

export default Activation;
