import React from 'react';
import Select from 'react-select';
import { Controller, Control } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import {
  styleBase,
  styleBold,
} from '../style';

type OptionType = {
  value: string
  label: string | null
};

interface SelectProps {
  name: string,
  control: Control<any>,
  options: OptionType[],
  rules?: any,
  widthLabel?: string | null,
  inline?: boolean,
  placeholder?: string,
  isMulti?: boolean,
  label?: string | null,
  className?: string,
  isClearable?: boolean,
  isSearchable?: boolean,
  defaultValue?: any,
  handleChange?: (val: any) => void,
  prop?: any,
}

const StyledSelect = ({
  name,
  control,
  rules,
  options,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  className,
  isClearable = false,
  isSearchable = false,
  defaultValue,
  handleChange,
  ...props
}: SelectProps) => {
  function getClassName() {
    let style;
    if (className === 'bold') style = styleBold;
    else style = {... styleBase};
    if (className === 'no-border'){
      style.control = (provided: any) => ({
        ...provided,
        boxShadow: '0 0 0 1px transparent',
        borderColor: 'transparent',
        borderWidth: '1px',
        backgroundColor: 'transparent',
        minHeight: '40px',
        minWidth: '150px',
        borderRadius: '3px',
        cursor: 'pointer',
        '&:hover': {
          borderColor: '',
        },
      })
    }
    return style;
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <Select
            {...field}
            {...props}
            value={
              field.value ? options?.find((option: OptionType) => option.value === field.value) : null
            }
            options={options}
            onChange={(opt: OptionType) => {
              field.onChange(opt?.value || opt);
              if (handleChange) handleChange(opt);
            }}
            defaultValue={defaultValue}
            isClearable={isClearable}
            isSearchable={isSearchable}
            className={`${styles.select}`}
            placeholder={placeholder}
            styles={getClassName()}
          />
        </div>
      )}
    />
  );
};

export default StyledSelect;
