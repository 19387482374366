import React, {useMemo} from 'react';
import { useOutletContext } from 'react-router-dom';
import { ImLink } from 'react-icons/im';
import styles from './moodboard.module.scss';
import { IFile } from '../../../../types/files';
import IActivation from '../../../../types/activation';

interface IActivationProps {
  activation: IActivation,
}

const Moodboard = ({ activation } : IActivationProps) => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const trimJWT = useMemo(() => (token ? token.replace('JWT ', '') : null), [token]);
  const renderImage = (picture: IFile) => {
    let img = (
      <a href={`${process.env.REACT_APP_API_URL}/files/public/${picture.path}?token=${token}`} target="_blank">
        <img
          alt={picture.alt}
          src={`${process.env.REACT_APP_API_URL}/files/public/${picture.path}?token=${token}`}
        />
      </a>
    );
    if (picture.mimetype === 'video/mp4') {
      img = (
        <a href={`${process.env.REACT_APP_API_URL}/files/public/${picture.path}?token=${token}`} target="_blank">
          <video controls={false}>
            <source
              src={`${process.env.REACT_APP_API_URL}/files/public/${picture.path}?token=${trimJWT}`}
              type={picture.mimetype}>
            </source>
          </video>
        </a>  
      );
    }else if (picture.url) {
      img = (
        <a href={`https://${picture.url}`}>
          {img}
          <div className={styles.icon}>
            <ImLink />
          </div>
        </a>
      );
    }
    
    return (
      <div key={`image-${picture._id}`}>
        {img}
      </div>
    );
  };

  const renderVideo = (video: any) => {
    return (
      <a key={`video-${video}`} href={video} target="_blank">
        {video}
      </a>
    );
  };

  return (
    <div className={styles.moodboard}>
      {activation.files.length > 0 && (
        <div className={`${styles.moodboard} ${styles[`grid${activation.files.length}`]}`}>
          {activation.files.map((picture: IFile) => renderImage(picture))}
        </div>
      )}
      {activation.videos.length > 0 && (
        <div className={styles.videos}>
          {activation.videos.map((video: String) => renderVideo(video))}
        </div>
      )}
    </div>
  );
};

export default Moodboard;
